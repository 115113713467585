import React from 'react'
import Homepage360Icon from '../../../icons/Homepage360Icon'
import HomepageAerialIcon from '../../../icons/HomepageAerialIcon'
import HomepageCalculatorIcon from '../../../icons/HomepageCalculatorIcon'

type Props = {}

const HelpfulToolsSection = (props: Props) => {
    return (
        <div className={`pb-10 container hidden lg:block`}>
            <h2 className="text-lg text-black-#3D3D3D lg:text-32px font-bold leading-snug mb-0 lg:mb-1.5">Helpful Tools for You</h2>
            <p className="text-xs lg:text-base text-gray-#8F8F8F mb-6 lg:mb-8">
                We provide full services at every step
            </p>
            <div className='flex flex-row w-full gap-5'>
                <a href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/search?has_360=1`} className='rounded-lg border border-gray-dcdcdc px-22px py-18px flex flex-row items-center flex-1 hover:border-main-light shadow-none hover:shadow-testimonial duration-150 transition-all ease-out cursor-pointer'>
                    <div className='w-16 h-16 flex-0 flex-shrink-0 mr-3 flex items-center justify-center rounded-full bg-red-#FFEEEF text-main'>
                        <Homepage360Icon />
                    </div>
                    <div className='flex flex-col'>
                        <div className='mb-1 flex flex-row items-center'>
                            <p className='text-xl font-bold'>Virtual Tour</p>
                            <div className='bg-main text-white text-10px text-center py-5px px-6px rounded-md ml-2 font-bold'>Only on E-Catalog</div>
                        </div>
                        <span className='text-gray-#8F8F8F text-sm'>Explore every inch of your dream home with our Virtual Tour</span>
                    </div>
                </a>
                <a href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/aerial`} className='rounded-lg border border-gray-dcdcdc px-22px py-18px flex flex-row items-center flex-1 hover:border-main-light shadow-none hover:shadow-testimonial duration-150 transition-all ease-out cursor-pointer'>
                    <div className='w-16 h-16 flex-0 flex-shrink-0 mr-3 flex items-center justify-center rounded-full bg-red-#FFEEEF text-main'>
                        <HomepageAerialIcon />
                    </div>
                    <div className='flex flex-col'>
                        <p className='mb-1 text-xl font-bold'>View from The Sky</p>
                        <span className='text-gray-#8F8F8F text-sm'>Providing a unique and immersive viewing experience.</span>
                    </div>
                </a>
                <a href={`${process.env.NEXT_PUBLIC_MAINSITE_URL}/kpr-calculator`} className='rounded-lg border border-gray-dcdcdc px-22px py-18px flex flex-row items-center flex-1 hover:border-main-light shadow-none hover:shadow-testimonial duration-150 transition-all ease-out cursor-pointer'>
                    <div className='w-16 h-16 flex-0 flex-shrink-0 mr-3 flex items-center justify-center rounded-full bg-red-#FFEEEF text-main'>
                        <HomepageCalculatorIcon />
                    </div>
                    <div className='flex flex-col'>
                        <p className='mb-1 text-xl font-bold'>Financial Calculator</p>
                        <span className='text-gray-#8F8F8F text-sm'>Find the one that best suits your financial situation and goals.</span>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default HelpfulToolsSection